import { push, navigate } from "gatsby";
import { getStore } from "./Store";
import Actions from "./Reducers/Actions";
import ApiProxy from "./ApiProxy";
import Catchify from "./Utils/Catchify";
import API from "./Api";
import * as Services from "./Services";

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      let lang = getStore().getState().lang;
      if (!param) {
        navigate(`/${lang}${url}`);
      } else {
        navigate(`/${lang}${url}`, param);
      }
    })
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        if (!response.user.is_staff) {
          throw new Error("This account is not staff!");
        }
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        throw err;
      }
    ),

    getMyProfile: Catchify(
      async () => {
        let response = await API.getMyProfile();
        dispatch({ type: Actions.AUTO_LOGIN, payload: response });
      },

      async err => {
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let token = window.localStorage.getItem("token");
        ApiProxy.setToken(token);
        if (token) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    socialSingin: async ({ socialType, token }) => {
      try {
        let response = await API.socialSingin({ socialType, token });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        return Promise.resolve();
      } catch (err) {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        return Promise.reject(err);
      }
    },

    editMyProfile: Catchify(
      async data =>
        dispatch({
          type: Actions.AUTO_LOGIN,
          payload: await API.editMyProfile(data)
        }),
      err => Promise.reject(err)
    ),

    preCheckout: Catchify(
      async data => {
        let result = await API.preCheckout(JSON.stringify(data));
        return result;
      },
      err => Promise.reject(err)
    ),

    createOrder: Catchify(
      async data => {
        let result = await API.createOrder(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    getCart: Catchify(
      async (id, values) => {
        const STORAGE_ID = "cart";
        let cart = { items: [] };

        try {
          cart = JSON.parse(window.localStorage.getItem(STORAGE_ID));
        } catch (err) {
          console.warn(err);
        }

        return cart;
      },
      err => Promise.reject(err)
    ),

    updateCart: Catchify(
      async (id, values) => {
        const STORAGE_ID = "cart";
        let cart = { items: [] };

        try {
          cart = JSON.parse(window.localStorage.getItem(STORAGE_ID));
        } catch (err) {
          console.warn(err);
          cart = { items: [] };
        }

        if (values) {
          cart.items[id] = values;
        } else {
          delete cart.items[id];
        }

        try {
          window.localStorage.setItem(STORAGE_ID, JSON.stringify(cart));
        } catch (err) {
          console.warn(err);
        }

        return cart;
      },
      err => Promise.reject(err)
    ),

    paypalOnSuccess: Catchify(
      async (uid, data) => {
        return await API.paypalOnSuccess(uid, JSON.stringify(data));
      },
      err => Promise.reject(err)
    ),

    getOrderList: Catchify(
      async () => {
        let result = await API.getOrderList();
        dispatch({ type: Actions.SET_ORDERS, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrder: Catchify(
      async id => {
        let result = await API.getOrder(id);
        dispatch({ type: Actions.SET_ORDER, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    getMirrorStatus: Catchify(
      async () => {
        let name = window.localStorage.getItem("mirror-number");
        let result = await API.getMirrorStatus(name);
        return result;
      },
      err => Promise.reject(err)
    ),

    endCourseSession: Catchify(
      async id => {
        let result = await API.endCourseSession(id);
        return result;
      },
      err => Promise.reject(err)
    ),

    ...Services.getActionCreators(getStore())
  }
});
