const AppConfig = {
  navbar: {
    coverContent: true,
    background: 'rgba(0,0,0,0)',
    altBackground: '#0079ff',
    textColor: 'white',
    textShadow: '2px 2px #ccc',
    height: 60,
  },

  footer: {},
};

export default AppConfig;
